import * as React from 'react';
import axios from 'axios';
import {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Kpi from './kpi/Kpi';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

export default function Dashboard() {

const [dashboard, setDashboard] = useState([]);
const [dateRange, setDateRange] = useState('THIS_YEAR');
const [loading, setLoading] = useState(false);

const getDashboard = async () => {
    setLoading(true);
    let dashboard  = await axios.get(`https://008joelsoe.execute-api.us-east-2.amazonaws.com/prod/?range=THIS_YEAR`);
    setDashboard(dashboard.data);
    console.log(dashboard.data);
    setLoading(false);
  };

  const handleChange= async (event) => {
    setLoading(true);
    let dateRange = event.target.value;
    setDateRange(dateRange);
    let dashboard  = await axios.get(`https://008joelsoe.execute-api.us-east-2.amazonaws.com/prod/?range=${dateRange}`);
    setDashboard(dashboard.data);
    console.log(dashboard.data);
    setLoading(false);
  };

useEffect( () => {
    // Update the document title using the browser API
    getDashboard();
  }, []);


  return (
    <div>
        <div>
          <Grid container spacing={2} sx={{marginBottom: 5}} >
          <Grid item xs={2}>
            <FormControl >
                <InputLabel id="date-range-label">Date Range</InputLabel>
                <Select
                labelId="date-range-label"
                id="date-range-select"
                value={dateRange}
                label="Date Range"
                onChange={handleChange}
                >
                <MenuItem value={"THIS_YEAR"}>This Year</MenuItem>
                <MenuItem value={"THIS_QUARTER"}>This Quarter</MenuItem>
                <MenuItem value={"THIS_MONTH"}>This Month</MenuItem>
                <MenuItem value={"LAST_YEAR"}>Last Year</MenuItem>
                <MenuItem value={"LAST_QUARTER"}>Last Quarter</MenuItem>
                <MenuItem value={"LAST_MONTH"}>Last Month</MenuItem>
                </Select>
            </FormControl>
            </Grid>
            <Grid item xs={2}>
            { loading &&
              <CircularProgress sx={{marginLeft: 5}}/>

            }
            </Grid>
        </Grid>
        </div>
    <Grid container spacing={2}  alignItems="stretch">

        {dashboard.map(d => (
             <Grid item xs={3} key={d.id} >
                <Kpi
                    kpi={d.dataResult.toLocaleString()} 
                    title={d.first_name}
                />
            </Grid>
        ))}
     
    </Grid>
    
    </div>


  );
}